import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Squash as Hamburger } from 'hamburger-react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import bgDialog from '../images/bg-dialog.png';
import ProjectLogo from './ProjectLogo';
import TargetBlankLink from './TargetBlankLink';

const NavigationOnepage = ({ menuColor, pageSliderRef }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [hamburgerColor, setHamburgerColor] = React.useState(menuColor);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  React.useEffect(() => {
    setHamburgerColor(menuColor);
  });

  // const navigationItems = ['01 Videos', '02 Timeline', '03 Know Your Kopi'];
  const navigationItems = [
    {
      key: 0,
      text: '01 Videos',
      link: '/',
    },
    {
      key: 1,
      text: '02 Timeline',
      link: '/timeline/',
    },
    {
      key: 2,
      text: '03 Know Your Kopi',
      link: '/kopi/',
    },
    {
      key: 3,
      text: '04 Coffee Break Tales',
      link: '/tales/',
    },
  ];

  const handleMenuOpen = () => {
    setActiveIndex(pageSliderRef.current.swiper.activeIndex);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'absolute',
          top: {
            // default 13px
            xs: '7px',
            sm: '17px',
          },
          right: {
            // default 8px
            xs: '12px',
            sm: '22px',
          },
          zIndex: 1310,
        }}
        onClick={handleMenuOpen}
      >
        <Hamburger
          color={isOpen ? colorSecondary : menuColor}
          toggled={isOpen}
          toggle={setOpen}
        />
      </Box>
      <Dialog
        id="navigation-dialog"
        fullScreen
        open={isOpen}
        onClose={handleMenuClose}
        BackdropProps={{
          style: {
            background: '#f2ece8', // this is essential to remove transition fade
          },
        }}
        PaperProps={{
          style: {
            background: `url(${bgDialog})`,
          },
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            padding: {
              xs: '30px 20px',
              md: '50px 80px',
              lg: '80px 120px',
            },
          }}
        >
          <List
            sx={{
              color: '#000000',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            {navigationItems.map(item => (
              <ListItem
                key={item.key}
                disablePadding
                className={'font-family-secondary'}
                sx={{
                  display: 'block',
                  '&:not(:last-child)': {
                    marginBottom: {
                      xs: '15px',
                      lg: '25px',
                    },
                  },
                }}
              >
                <ListItemText
                  className={`navigation-link ${
                    activeIndex === item.key ? 'active' : ''
                  }`}
                  // className={`navigation-link`}
                  disableTypography={true}
                  sx={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    fontSize: {
                      xs: '1.75rem',
                      md: '2.75rem',
                      lg: '3.125rem',
                    },
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    pageSliderRef.current.swiper.slideTo(item.key);
                    handleMenuClose();
                  }}
                >
                  {item.text}
                  {/*<Link*/}
                  {/*  className={navigationLink}*/}
                  {/*  to={item.link}*/}
                  {/*  activeStyle={{ color: colorSecondary }}*/}
                  {/*>*/}
                  {/*  {item.text}*/}
                  {/*</Link>*/}
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: 'flex',
              flexFlow: {
                xs: 'column',
                sm: 'row',
              },
              alignItems: {
                xs: 'flex-start',
                sm: 'center',
              },
              marginTop: '50px',
              height: {
                lg: '56px',
              },
            }}
          >
            <ProjectLogo dividerColor={'rgba(0, 0, 0, 0.7)'} />
            <Typography
              sx={{
                color: colorPrimary,
                fontSize: {
                  xs: '0.6875rem',
                  sm: '0.875rem',
                },
                maxWidth: { sm: '360px' },
                marginLeft: {
                  xs: 0,
                  sm: '30px',
                },
                marginTop: {
                  xs: '10px',
                  sm: 0,
                },
              }}
            >
              This project was produced by{' '}
              <TargetBlankLink
                className={'text-primary'}
                href={'https://www.plugplayproductions.com/'}
                label={'Plug + Play Productions'}
              />{' '}
              with the support of SG Stories Content Fund Season 2 by{' '}
              <TargetBlankLink
                className={'text-primary'}
                href={'https://www.stb.gov.sg/content/stb/en.html'}
                label={'Singapore Tourism Board'}
              />
              .
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default NavigationOnepage;
