import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/mousewheel';

import Seo from '../components/Seo';
import NavigationOnepage from '../components/NavigationOnepage';
import PageIndex from '../components/PageIndex';
import PageTimeline from '../components/PageTimeline';
import PageKopi from '../components/PageKopi';
import PageVideo from '../components/PageVideo';

const IndexPage = () => {
  const [navMenuColor, setNavMenuColor] = React.useState('#ffffff');
  const pageSlider = React.useRef(null);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const siteTitle = site.siteMetadata.title;
  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const handleSlideChange = () => {
    if (pageSlider.current.swiper.activeIndex === 0) {
      setNavMenuColor('#ffffff')
    } else if (pageSlider.current.swiper.activeIndex > 0) {
      setNavMenuColor(colorSecondary)
    }
  };

  return (
    <React.Fragment>
      <Seo title={siteTitle} />
      <NavigationOnepage menuColor={navMenuColor} pageSliderRef={pageSlider} />
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          boxSizing: 'border-box',
          color: '#ffffff',
        }}
      >
        <Swiper
          modules={[Mousewheel]}
          direction={'vertical'}
          loop={false}
          autoplay={false}
          simulateTouch={true}
          allowTouchMove={true}
          mousewheel={{
            forceToAxis: true,
          }}
          speed={600}
          ref={pageSlider}
          // observer={true}
          // observeParents={true}
          // ref={timelineSwiper}
          breakpoints={{
            1200: {
              simulateTouch: false,
              allowTouchMove: false,
            },
          }}
          onSlideChange={handleSlideChange}
          id="page-slider"
        >
          <SwiperSlide><PageIndex /></SwiperSlide>
          <SwiperSlide><PageTimeline /></SwiperSlide>
          <SwiperSlide><PageKopi pageSliderRef={pageSlider} /></SwiperSlide>
          <SwiperSlide><PageVideo /></SwiperSlide>

          {/*<SwiperSlide><Paper sx={{background: 'red', width: '100%', height: '100vh'}}></Paper></SwiperSlide>*/}
          {/*<SwiperSlide><Paper sx={{background: 'blue', width: '100%', height: '100vh'}}></Paper></SwiperSlide>*/}
          {/*<SwiperSlide><Paper sx={{background: 'green', width: '100%', height: '100vh'}}></Paper></SwiperSlide>*/}
        </Swiper>
      </Container>
    </React.Fragment>
  );

};

export default IndexPage;
