import * as React from 'react';
import Box from '@mui/material/Box';

import PrevButtonIcon from '../images/prev-button.inline.svg';

const PrevButton = ({ handleClick, style, isVertical }) => {
  return (
    <Box
      sx={{
        width: {
          xs: '40px',
          sm: '44px',
        },
        height: {
          xs: '40px',
          sm: '44px',
        },
        cursor: 'pointer',
        transform: isVertical ? 'rotate(90deg)' : 'none',
      }}
      onClick={handleClick}
      style={style}
    >
      <PrevButtonIcon style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

export default PrevButton;
