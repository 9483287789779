import * as React from 'react';
import Box from '@mui/material/Box';

import NextButtonIcon from '../images/next-button.inline.svg';

const NextButton = ({ handleClick, style, isVertical }) => {
  const myMarginTop = {
    xs: '20px',
    md: '28px',
  };

  const myMarginLeft = '20px';

  return (
    <Box
      sx={{
        width: {
          xs: '40px',
          sm: '44px',
        },
        height: {
          xs: '40px',
          sm: '44px',
        },
        cursor: 'pointer',
        marginTop: isVertical ? myMarginTop : 0,
        marginLeft: !isVertical ? myMarginLeft : 0,
        transform: isVertical ? 'rotate(90deg)' : 'none',
      }}
      onClick={handleClick}
      style={style}
    >
      <NextButtonIcon style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

export default NextButton;
