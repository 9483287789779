import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';

import bgDialog from '../images/bg-dialog.png';
import CloseIcon from '../images/close-icon.inline.svg';

const TimelineDialog = ({ open, close, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  return (
    <Dialog
      className={'wave-dialog'}
      open={open}
      fullWidth={true}
      maxWidth={false}
      sx={{
        zIndex: '1400',
      }}
      PaperProps={{
        sx: {
          background: `url(${bgDialog})`,
          // margin: '10px',
          margin: {
            xs: '10px',
            md: '30px',
          },
          width: {
            xs: 'calc(100% - 20px)',
            md: '600px',
            lg: '720px',
            xl: '1000px',
          },
          height: {
            xs: 'calc(100% - 20px)',
            md: 'calc(100% - 60px)',
          },
          maxHeight: 'none',
        },
      }}
      onBackdropClick={close}
    >
      <Box
        onClick={close}
        sx={{
          color: colorSecondary,
          width: {
            xs: '28px',
            sm: '40px',
          },
          height: {
            xs: '28px',
            sm: '40px',
          },
          cursor: 'pointer',
          position: 'absolute',
          top: {
            xs: '12px',
            sm: '20px',
          },
          right: {
            xs: '12px',
            sm: '20px',
          },
        }}
      >
        <CloseIcon style={{ width: '100%', height: '100%' }} />
      </Box>
      <Container
        sx={{
          background: '#f2ece8',
          height: '100%',
          padding: {
            xs: '50px 20px 20px',
            md: '50px 60px',
            lg: '40px 90px',
            xl: '50px 130px',
          },
        }}
      >
        {children}
        <Box
          sx={{
            height: {
              xs: '20px',
              md: '50px',
              lg: '40px',
              xl: '50px',
            },
          }}
        >&nbsp;</Box>
      </Container>
    </Dialog>
  );
};

export default TimelineDialog;
