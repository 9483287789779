import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import PrevButton from '../components/PrevButton';
import NextButton from '../components/NextButton';
import KopiCard from '../components/KopiCard';

import bgDialog from '../images/bg-dialog.png';

const PageKopi = ({ pageSliderRef }) => {
  const kopiSwiper = React.useRef(null);
  const kopiCard1 = React.useRef(null);
  const kopiCard2 = React.useRef(null);
  const kopiCard3 = React.useRef(null);
  const kopiCard4 = React.useRef(null);
  const kopiCard5 = React.useRef(null);
  const kopiCard6 = React.useRef(null);
  const kopiCard7 = React.useRef(null);
  const kopiCard8 = React.useRef(null);
  const kopiCard9 = React.useRef(null);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const handlePrevButton = () => {
    kopiSwiper.current.swiper.slidePrev();
  };

  const handleNextButton = () => {
    kopiSwiper.current.swiper.slideNext();
  };

  const handleSlideChange = () => {
    const swiper = kopiSwiper.current.swiper;

    const activeFlippyCards = swiper.el.querySelectorAll(
      '.flippy-cardContainer.isActive'
    );
    const activeFlippyCardsContainer = [...activeFlippyCards];
    if (activeFlippyCardsContainer.length > 0) {
      activeFlippyCardsContainer.forEach(item => {
        item.classList.remove('isActive');
      });
    }
  };

  return (
    <React.Fragment>
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          boxSizing: 'border-box',
          background: `url(${bgDialog})`,
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            padding: {
              xs: '60px 20px 20px',
              sm: '100px 30px 30px',
              md: '80px 80px 40px',
              lg: '80px 80px 40px',
              xl: '80px 80px 40px',
            },
          }}
        >
          <Typography
            className={'font-family-secondary'}
            variant="h1"
            sx={{
              color: colorPrimary,
              fontWeight: 'bold',
              fontSize: {
                xs: '2.375rem',
                sm: '2.75rem',
                md: '3.25rem',
                lg: '4rem',
              },
              marginBottom: {
                xs: '0.25rem',
                sm: '0.5rem',
              },
            }}
          >
            Know Your Kopi
          </Typography>
          <Typography
            sx={{
              color: colorPrimary,
              fontSize: {
                xs: '0.875rem',
                lg: '1rem',
              },
            }}
          >
            Want to know a little better, what goes into your traditional
            coffee? Click through our cards to learn about the ratios.
          </Typography>
          <Box display={'flex'} marginTop={{ xs: '20px', md: '40px' }}>
            <PrevButton handleClick={handlePrevButton} />
            <NextButton handleClick={handleNextButton} />
          </Box>
        </Box>

        <Box
          sx={{
            paddingBottom: {
              xs: '20px',
              md: '40px',
              // lg: '80px',
              // xl: '100px',
            },
          }}
        >
          <Swiper
            spaceBetween={16}
            slidesPerView="auto"
            loop={true}
            loopedSlides={3}
            centeredSlides={true}
            initialSlide={0}
            ref={kopiSwiper}
            slidesOffsetBefore={0}
            breakpoints={{
              900: {
                spaceBetween: 20,
                initialSlide: 1,
              },
              1200: {
                spaceBetween: 24,
                initialSlide: 1,
              },
              1536: {
                spaceBetween: 24,
                slidesOffsetBefore: 200,
                initialSlide: 2,
              },
            }}
            onSlideChangeTransitionStart={handleSlideChange}
            id="kopi-slider"
          >
            <SwiperSlide>
              <KopiCard type="kopi" innerRef={kopiCard1} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiC" innerRef={kopiCard2} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiSiewDai" innerRef={kopiCard3} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiOKosong" innerRef={kopiCard4} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiGau" innerRef={kopiCard5} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiGahDai" innerRef={kopiCard6} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiO" innerRef={kopiCard7} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiPok" innerRef={kopiCard8} />
            </SwiperSlide>
            <SwiperSlide>
              <KopiCard type="kopiPeng" innerRef={kopiCard9} />
            </SwiperSlide>
          </Swiper>

          <Typography
            display={{ xs: 'block', md: 'none' }}
            fontSize={'0.6875rem'}
            color={colorSecondary}
            marginTop={{ xs: '12px', md: '30px', xl: '40px' }}
            textAlign={'center'}
          >
            &lt;&lt; Swipe left & right to explore &gt;&gt;
            <br />
            Click on the cards to find out more
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default PageKopi;

/*
const swiperRef = React.useRef(null);

return (
  <>
    <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} />
    <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} />
    <Swiper
      ref={swiperRef}
    >
      <SwiperSlide>
        Slide 1
      </SwiperSlide>
      <SwiperSlide>
        Slide 2
      </SwiperSlide>
    </Swiper>
  </>
)
 */
