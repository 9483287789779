import * as React from 'react';

const TargetBlankLink = ({ className, href, label }) => {

  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      {label ? label : href}
    </a>
  );
};

export default TargetBlankLink;
