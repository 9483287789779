import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';

import bgDialog from '../images/bg-dialog.png';
import CloseIcon from '../images/close-icon.inline.svg';

const ThumbnailButtonDialog = ({  open, close, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        sx={{
          zIndex: '1400',
        }}
        BackdropProps={{
          style: {
            background: '#f2ece8', // this is essential to remove transition fade
          },
        }}
        PaperProps={{
          style: {
            background: `url(${bgDialog})`,
            color: colorPrimary,
          },
        }}
      >
        <Box
          onClick={close}
          sx={{
            color: colorSecondary,
            width: {
              xs: '28px',
              sm: '40px',
            },
            height: {
              xs: '28px',
              sm: '40px',
            },
            cursor: 'pointer',
            position: 'absolute',
            top: {
              xs: '12px',
              sm: '20px',
            },
            right: {
              xs: '12px',
              sm: '20px',
            },
          }}
        >
          <CloseIcon style={{ width: '100%', height: '100%' }} />
        </Box>
        <Container
          sx={{
            height: '100%',
            padding: {
              xs: '60px 20px 20px',
              sm: '80px',
            },
          }}
        >
          {children}
        </Container>
      </Dialog>
    </React.Fragment>
  );
};

export default ThumbnailButtonDialog;
