import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import LogoPmp from '../images/logo-pmp.inline.svg';
import logoPnp from '../images/logo-pnp.png';

const ProjectLogo = ({dividerColor}) => {
  return (
    <Box
      height={{
        xs: '30px',
        sm: '44px',
      }}
      sx={{
        display: 'flex',
      }}
    >
      <Box
        width={'auto'}
        height={{
          xs: '30px',
          sm: '43px',
        }}
      >
        <LogoPmp className={'logo-pmp'} style={{ width: 'auto', height: '100%' }} />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: dividerColor,
          margin: {
            xs: '0 18px',
            md: '0 28px',
          },
          alignSelf: 'center',
          height: {
            xs: '90%',
            sm: '100%',
          },
        }}
      />
      <Box
        width={'auto'}
        height={{
          xs: '32px',
          sm: '45px',
        }}
      >
        <img src={logoPnp} alt="plug+play productions" style={{ width: 'auto', height: '100%' }} />
      </Box>
    </Box>
  );
};

export default ProjectLogo;
