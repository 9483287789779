import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const TimelineSlide = ({
  bgMobile,
  bgDesktop,
  overlayColor,
  title,
  description,
  buttonClick,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage: {
          xs: `url(${bgMobile})`,
          md: `url(${bgDesktop})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10px 0px 0px 10px',
        boxSizing: 'border-box',
        width: '100%',
        height: {
          xs: '400px',
          lg: '540px',
          xl: '700px',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: overlayColor,
          borderRadius: '10px 0px 0px 10px',
          color: '#ffffff',
          width: '100%',
          height: '100%',
          padding: {
            xs: '30px 20px',
            md: '60px',
            lg: '100px 90px',
          },
        }}
      >
        <Typography
          className={'font-family-secondary'}
          fontSize={{
            xs: '1.75rem',
            md: '2.125rem',
            lg: '2.5rem',
          }}
          maxWidth={{ xs: '100%', lg: '720px' }}
        >
          {title}
        </Typography>
        <Typography
          marginTop={{ xs: '5px' }}
          maxWidth={{ xs: '100%', lg: '720px' }}
        >
          {description}
        </Typography>
        <Button
          color="white"
          variant="outlined"
          onClick={buttonClick}
          sx={{
            fontSize: {
              xs: '0.875rem',
              md: '1rem',
            },
            width: {
              xs: '120px',
              md: '140px',
            },
            height: {
              xs: '40px',
              md: '44px',
            },
            marginTop: {
              xs: '20px',
              md: '40px',
            },
          }}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default TimelineSlide;
