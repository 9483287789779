import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import PrevButton from '../components/PrevButton';
import NextButton from '../components/NextButton';
import KopiCard from '../components/KopiCard';
import IframeEmbed from './IframeEmbed';
import ThumbnailButton from './ThumbnailButton';
import ThumbnailButtonDialog from './ThumbnailButtonDialog';

import bgDialog from '../images/bg-dialog.png';
import thumbnail1 from '../images/tales/thumbnail-1.jpg';
import thumbnail2 from '../images/tales/thumbnail-2.jpg';
import thumbnail3 from '../images/tales/thumbnail-3.jpg';
import thumbnail4 from '../images/tales/thumbnail-4.jpg';
import thumbnail5 from '../images/tales/thumbnail-5.jpg';
import thumbnail6 from '../images/tales/thumbnail-6.jpg';
import thumbnail7 from '../images/tales/thumbnail-7.jpg';
import thumbnail8 from '../images/tales/thumbnail-8.jpg';
import thumbnail9 from '../images/tales/thumbnail-9.jpg';
import thumbnail10 from '../images/tales/thumbnail-10.jpg';
import thumbnail11 from '../images/tales/thumbnail-11.jpg';
import thumbnail12 from '../images/tales/thumbnail-12.jpg';

const PageVideo = ({pageSliderRef}) => {
  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [dialog3Open, setDialog3Open] = React.useState(false);
  const [dialog4Open, setDialog4Open] = React.useState(false);
  const [dialog5Open, setDialog5Open] = React.useState(false);
  const [dialog6Open, setDialog6Open] = React.useState(false);
  const [dialog7Open, setDialog7Open] = React.useState(false);
  const [dialog8Open, setDialog8Open] = React.useState(false);
  const [dialog9Open, setDialog9Open] = React.useState(false);
  const [dialog10Open, setDialog10Open] = React.useState(false);
  const [dialog11Open, setDialog11Open] = React.useState(false);
  const [dialog12Open, setDialog12Open] = React.useState(false);

  const videoSwiper = React.useRef(null);

  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const handleDialog1Open = () => {
    setDialog1Open(true);
  };

  const handleDialog1Close = () => {
    setDialog1Open(false);
  };

  const handleDialog2Open = () => {
    setDialog2Open(true);
  };

  const handleDialog2Close = () => {
    setDialog2Open(false);
  };

  const handleDialog3Open = () => {
    setDialog3Open(true);
  };

  const handleDialog3Close = () => {
    setDialog3Open(false);
  };

  const handleDialog4Open = () => {
    setDialog4Open(true);
  };

  const handleDialog4Close = () => {
    setDialog4Open(false);
  };

  const handleDialog5Open = () => {
    setDialog5Open(true);
  };

  const handleDialog5Close = () => {
    setDialog5Open(false);
  };

  const handleDialog6Open = () => {
    setDialog6Open(true);
  };

  const handleDialog6Close = () => {
    setDialog6Open(false);
  };

  const handleDialog7Open = () => {
    setDialog7Open(true);
  };

  const handleDialog7Close = () => {
    setDialog7Open(false);
  };

  const handleDialog8Open = () => {
    setDialog8Open(true);
  };

  const handleDialog8Close = () => {
    setDialog8Open(false);
  };

  const handleDialog9Open = () => {
    setDialog9Open(true);
  };

  const handleDialog9Close = () => {
    setDialog9Open(false);
  };

  const handleDialog10Open = () => {
    setDialog10Open(true);
  };

  const handleDialog10Close = () => {
    setDialog10Open(false);
  };

  const handleDialog11Open = () => {
    setDialog11Open(true);
  };

  const handleDialog11Close = () => {
    setDialog11Open(false);
  };

  const handleDialog12Open = () => {
    setDialog12Open(true);
  };

  const handleDialog12Close = () => {
    setDialog12Open(false);
  };

  const videos = [
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F612614823126360%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail1,
      thumbnailDescription: 'A Tale of Two Men',
      dialogState: dialog1Open,
      dialogDescription: `What's in a name? Who are the two men, Chye Seng Huat, and Papa Palheta, and what do they have in common?`,
      onDialogOpen: handleDialog1Open,
      onDialogClose: handleDialog1Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F259814442915209%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail2,
      thumbnailDescription: 'Singapore Coffee Association and ASEAN Coffee Federation',
      dialogState: dialog2Open,
      dialogDescription: `The Singapore Coffee Association is older than even Singapore herself! Find out more about them, and the ASEAN Coffee Federation.`,
      onDialogOpen: handleDialog2Open,
      onDialogClose: handleDialog2Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F320516243281395%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail3,
      thumbnailDescription: 'Ella Goes to Japan',
      dialogState: dialog3Open,
      dialogDescription: `A coffee to go with your early morning commute - what could be more efficient. We learn about 'Ella', and their plans with Japan Rail, as well as SMRT.`,
      onDialogOpen: handleDialog3Open,
      onDialogClose: handleDialog3Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F350017026578359%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail4,
      thumbnailDescription: 'A Little Bit of Crazy Rich',
      dialogState: dialog4Open,
      dialogDescription: `The wedding couple was crazy - about the location, that is. Glasshouse's Josh, tells us a brief anecdote about a Hawaiian couple's destination wedding plans, inspired by the movie Crazy Rich Asians.`,
      onDialogOpen: handleDialog4Open,
      onDialogClose: handleDialog4Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F465768651668580%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail5,
      thumbnailDescription: 'Rise of the Tau Chiu Robots',
      dialogState: dialog5Open,
      dialogDescription: `Robots will take over the world! But first, they'll learn how to make us coffee. Enjoy this rise of the machines, while it lasts...`,
      onDialogOpen: handleDialog5Open,
      onDialogClose: handleDialog5Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F396681632144216%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail6,
      thumbnailDescription: 'Kopi in the UK',
      dialogState: dialog6Open,
      dialogDescription: `A love story with Singapore's Kopi, led to Robert setting up Kopi House UK, in London.`,
      onDialogOpen: handleDialog6Open,
      onDialogClose: handleDialog6Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F644201383568845%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail7,
      thumbnailDescription: 'Lucidly Competing',
      dialogState: dialog7Open,
      dialogDescription: `Competition hones your craft, and makes you a better barista. Agree? Disagree? The guys from Lucid share their personal experiences.`,
      onDialogOpen: handleDialog7Open,
      onDialogClose: handleDialog7Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F275551918008650%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail8,
      thumbnailDescription: 'Cupping with Guan',
      dialogState: dialog8Open,
      dialogDescription: `Cupping coffee beans helps a roaster identify flavors, or defects, at the first stage of coffee making. Get an insider peek on this little seen aspect of the coffee world.`,
      onDialogOpen: handleDialog8Open,
      onDialogClose: handleDialog8Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F655070995531941%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail9,
      thumbnailDescription: 'A Love Letter to Kopi',
      dialogState: dialog9Open,
      dialogDescription: `Singapore Coffee is a homage to local kopi, a way to elevate and refresh the coffee experience here. Find out how they do this, against the iconic backdrop of Raffles Hotel.`,
      onDialogOpen: handleDialog9Open,
      onDialogClose: handleDialog9Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F3175941549402733%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail10,
      thumbnailDescription: '3G Coffee Family',
      dialogState: dialog10Open,
      dialogDescription: `When you have three generations of coffee in your blood, how could you not be an expert? We hear about the origins of Tiong Hoe Speciality Coffee.`,
      onDialogOpen: handleDialog10Open,
      onDialogClose: handleDialog10Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F683648979712949%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail11,
      thumbnailDescription: 'The Community Coffee Experience',
      dialogState: dialog11Open,
      dialogDescription: `What goes on at a Community Coffee Experience? Aslam tells us more.`,
      onDialogOpen: handleDialog11Open,
      onDialogClose: handleDialog11Close,
    },
    {
      src: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F649047706515603%2F&show_text=false&width=560&t=0',
      thumbnail: thumbnail12,
      thumbnailDescription: 'Travels with Nylon',
      dialogState: dialog12Open,
      dialogDescription: `Nylon's brews are a result of not just discerning selections, but of relationships built up over the years. We find out more about their travels and friends they've made, over the course of finding the best beans.`,
      onDialogOpen: handleDialog12Open,
      onDialogClose: handleDialog12Close,
    },
  ];

  const handlePrevButton = () => {
    videoSwiper.current.swiper.slidePrev();
  };

  const handleNextButton = () => {
    videoSwiper.current.swiper.slideNext();
  };

  const handleSlideChange = () => {
    const swiper = videoSwiper.current.swiper;

    const activeFlippyCards = swiper.el.querySelectorAll(
      '.flippy-cardContainer.isActive'
    );
    const activeFlippyCardsContainer = [...activeFlippyCards];
    if (activeFlippyCardsContainer.length > 0) {
      activeFlippyCardsContainer.forEach(item => {
        item.classList.remove('isActive');
      });
    }
  };

  return (
    <React.Fragment>
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          boxSizing: 'border-box',
          background: `url(${bgDialog})`,
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            padding: {
              xs: '60px 20px 20px',
              sm: '100px 30px 30px',
              md: '80px 80px 40px',
              lg: '80px 80px 40px',
              xl: '80px 80px 40px',
            },
          }}
        >
          <Typography
            className={'font-family-secondary'}
            variant="h1"
            sx={{
              color: colorPrimary,
              fontWeight: 'bold',
              fontSize: {
                xs: '2.375rem',
                sm: '2.75rem',
                md: '3.25rem',
                lg: '4rem',
              },
              marginBottom: {
                xs: '0.25rem',
                sm: '0.5rem',
              },
            }}
          >
            Coffee Break Tales
          </Typography>
          {/*<Typography*/}
          {/*  sx={{*/}
          {/*    color: colorPrimary,*/}
          {/*    fontSize: {*/}
          {/*      xs: '0.875rem',*/}
          {/*      lg: '1rem',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae pulvinar justo, non luctus ligula.*/}
          {/*  Curabitur a eros ac felis mattis interdum. Suspendisse potenti. Mauris pretium neque sem, et maximus urna*/}
          {/*  fringilla nec. Nam vitae diam vitae dui porta suscipit id sit amet enim. Etiam vel risus condimentum,*/}
          {/*  maximus enim eget, suscipit massa. Donec non nibh vitae lorem condimentum molestie id in enim. Ut nec urna*/}
          {/*  vulputate, suscipit libero sit amet, sollicitudin eros.*/}
          {/*</Typography>*/}
          <Box display={'flex'} marginTop={{xs: '20px', md: '40px'}}>
            <PrevButton handleClick={handlePrevButton}/>
            <NextButton handleClick={handleNextButton}/>
          </Box>
        </Box>

        <Box
          sx={{
            paddingBottom: {
              xs: '20px',
              md: '40px',
              // lg: '80px',
              // xl: '100px',
            },
          }}
        >
          <Swiper
            spaceBetween={16}
            slidesPerView="auto"
            loop={true}
            loopedSlides={3}
            centeredSlides={true}
            initialSlide={0}
            ref={videoSwiper}
            slidesOffsetBefore={0}
            breakpoints={{
              900: {
                spaceBetween: 20,
                initialSlide: 1,
              },
              1200: {
                spaceBetween: 24,
                initialSlide: 1,
              },
              1536: {
                spaceBetween: 24,
                slidesOffsetBefore: 200,
                initialSlide: 2,
              },
            }}
            onSlideChangeTransitionStart={handleSlideChange}
            id="kopi-slider"
          >
            {videos.map((item, index) => (
              <SwiperSlide>
                <ThumbnailButton onClick={item.onDialogOpen} image={item.thumbnail} larger={true}>
                  <Typography sx={{
                    color: colorPrimary,
                    fontSize: {
                      xs: '0.875rem',
                      lg: '1rem',
                    },
                  }}>
                    {index+1}/{videos.length}{' '}{item.thumbnailDescription}
                  </Typography>
                </ThumbnailButton>
              </SwiperSlide>
            ))}
          </Swiper>

          <Typography
            display={{xs: 'block', md: 'none'}}
            fontSize={'0.6875rem'}
            color={colorSecondary}
            marginTop={{xs: '12px', md: '30px', xl: '40px'}}
            textAlign={'center'}
          >
            &lt;&lt; Swipe left & right to explore &gt;&gt;
          </Typography>
        </Box>
      </Container>

      {videos.map(video => (
        <ThumbnailButtonDialog open={video.dialogState} close={video.onDialogClose}>
          <Grid container rowSpacing={0} columnSpacing={2.5} height={`100%`} sx={{alignContent: 'flex-start'}}>
            <Grid
              item
              xs={12}
            >
              <Box>
                <IframeEmbed
                  src={video.src}
                  width={'1280'}
                  height={'411'}
                  style={{
                    border: 'none',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: {md: '5px'},
                paddingBottom: {
                  xs: '20px',
                  sm: '40px',
                },
              }}
            >
              <Box>
                <Typography margin={'1.5rem 0'}>
                  {video.dialogDescription}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ThumbnailButtonDialog>
      ))}
    </React.Fragment>
  );
};

export default PageVideo;
