import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import ProjectLogo from './ProjectLogo';
import TargetBlankLink from './TargetBlankLink';
import ThumbnailButton from './ThumbnailButton';
import ThumbnailButtonDialog from './ThumbnailButtonDialog';
import YoutubeEmbed from './YoutubeEmbed';
import IframeEmbed from './IframeEmbed';
import bgHome from '../images/bg-home.jpg';
import thumbnail1 from '../images/thumbnail-1.png';
import thumbnail2 from '../images/thumbnail-2.png';
import thumbnail3 from '../images/thumbnail-3.png';
import IconMouse from '../images/mouse.inline.svg';

const PageIndex = () => {
  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [dialog3Open, setDialog3Open] = React.useState(false);

  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const ep1 = {
    title: 'Ep 1',
    description: `
      Nanyang kopi, a long-cherished bit of heritage to Singaporeans has held its own throughout the years, throughout the waves.
    `,
    facebookSrc: `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F844396246228800%2F&show_text=0`,
  };

  const ep2 = {
    title: 'Ep 2',
    description: `
      The different experiences of specialty coffee houses are unique to their respective locations.
    `,
    facebookSrc: `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F336740554492870%2F&show_text=0`,
  };

  const ep3 = {
    title: 'Ep 3',
    description: `
      What's the next wave of coffee?
    `,
    facebookSrc: `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fplugplayproductions%2Fvideos%2F377546960813223%2F&show_text=0`,
  };

  const handleDialog1Open = () => {
    setDialog1Open(true);
  };

  const handleDialog1Close = () => {
    setDialog1Open(false);
  };

  const handleDialog2Open = () => {
    setDialog2Open(true);
  };

  const handleDialog2Close = () => {
    setDialog2Open(false);
  };

  const handleDialog3Open = () => {
    setDialog3Open(true);
  };

  const handleDialog3Close = () => {
    setDialog3Open(false);
  };

  return (
    <React.Fragment>
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          background: `url(${bgHome})`,
          backgroundSize: 'cover',
          backgroundPosition: {
            xs: '90% 20%',
            md: '50% 50%',
          },
          boxSizing: 'border-box',
          color: '#ffffff',
          width: '100%',
          height: '100%',
          padding: {
            xs: '60px 20px 30px',
            sm: '100px 40px 50px',
            md: '105px 80px 90px',
            // lg: '120px 80px 100px',
            lg: '17vh 80px 100px',
            xl: '140px 80px 110px',
          },
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Box>
          <Typography
            variant="h1"
            className={`font-family-secondary`}
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '2.375rem',
                sm: '2.75rem',
                md: '3.25rem',
                lg: '4rem',
              },
              marginBottom: {
                xs: '0.5rem',
                sm: '1rem',
              },
            }}
          >
            From Beans 2 Brews: The Singapore Coffee Story
          </Typography>
          <Typography
            maxWidth={{xs: '100%', lg: '630px'}}
            fontSize={{xs: '0.875rem', sm: '1rem'}}
          >
            Serving up stories of traditional coffee in local kopitiams, specialty coffee houses with international
            followings, and even cutting edge technology that make beans to brews in faster, newer ways.
          </Typography>
          <Box
            sx={{
              width: {
                xs: '24px',
                sm: '30px',
              },
              height: {
                xs: '36px',
                sm: '44px',
              },
              marginTop: {
                xs: '20px',
                sm: '80px',
                md: '50px',
                lg: '80px',
              },
              marginBottom: '5px',
            }}
          >
            <IconMouse style={{width: '100%', height: 'auto'}}/>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: {
              md: 'flex-end',
            },
            marginTop: {
              xs: '10vw',
              sm: '150px',
              md: '0',
            },
          }}
        >
          <Box
            sx={{
              position: {
                xs: 'relative',
                // md: 'absolute',
              },
              marginBottom: {
                xs: '20px',
                md: '40px',
                lg: '20px',
                xl: '10px',
              },
              marginLeft: {
                md: 'auto',
              },
              order: {
                xs: '1',
                md: '2',
              },
              flex: '0 1 auto',
              // bottom: {
              //   md: '140px',
              //   lg: '140px',
              //   xl: '140px',
              // },
              // right: {
              //   md: '70px',
              //   lg: '80px',
              // },
            }}
          >
            <Typography
              fontSize={'0.6875rem'}
              marginBottom={'5px'}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              &lt;&lt; Swipe left & right to explore &gt;&gt;
            </Typography>
            <Swiper
              spaceBetween={10}
              slidesPerView="auto"
              loop={true}
              loopedSlides={2}
              // onSlideChange={() => console.log('slide change')}
              breakpoints={{
                600: {
                  slidesPerView: 3,
                  loop: true,
                  loopedSlides: 0,
                  allowTouchMove: false,
                },
                900: {
                  spaceBetween: 20,
                  slidesPerView: 3,
                  loop: true,
                  loopedSlides: 0,
                  allowTouchMove: false,
                },
                1200: {
                  spaceBetween: 24,
                  slidesPerView: 3,
                  loop: true,
                  loopedSlides: 0,
                  allowTouchMove: false,
                },
              }}
              id="home-slider"
            >
              <SwiperSlide>
                <ThumbnailButton onClick={handleDialog1Open} image={thumbnail1} title={ep1.title}></ThumbnailButton>
              </SwiperSlide>
              <SwiperSlide>
                <ThumbnailButton onClick={handleDialog2Open} image={thumbnail2} title={ep2.title}></ThumbnailButton>
              </SwiperSlide>
              <SwiperSlide>
                <ThumbnailButton onClick={handleDialog3Open} image={thumbnail3} title={ep3.title}></ThumbnailButton>
              </SwiperSlide>
            </Swiper>
          </Box>
          <Box
            sx={{
              order: {
                xs: '2',
                md: '1',
              },
              flex: {
                xs: '0 0 auto',
                md: '1 0 auto',
              },
              marginTop: {
                sm: 'auto',
              },
              maxWidth: {md: '250px', lg: '350px', xl: '480px'},
            }}
          >
            <ProjectLogo dividerColor={'rgba(255, 255, 255, 0.7)'}/>
            <Typography
              sx={{
                fontSize: {
                  xs: '0.6875rem',
                  sm: '0.875rem',
                },
                maxWidth: {md: '250px', lg: '350px', xl: '480px'},
                marginTop: {
                  xs: '0.625rem',
                  sm: '1rem',
                },
                marginRight: {
                  md: '5px',
                },
              }}
            >
              This project was produced by{' '}
              <TargetBlankLink
                className={'text-white'}
                href={'https://www.plugplayproductions.com/'}
                label={'Plug + Play Productions'}
              />{' '}
              with the support of SG Stories Content Fund Season 2 by{' '}
              <TargetBlankLink
                className={'text-white'}
                href={'https://www.stb.gov.sg/content/stb/en.html'}
                label={'Singapore Tourism Board'}
              />
              .
            </Typography>
          </Box>
        </Box>
      </Container>

      <ThumbnailButtonDialog open={dialog1Open} close={handleDialog1Close}>
        <Grid container rowSpacing={0} columnSpacing={2.5} height={`100%`}>
          <Grid
            item
            xs={12}
          >
            <Box>
              {/*<YoutubeEmbed*/}
              {/*  embedId="XZC-4zEC2O8"*/}
              {/*  className={'yt-embed'}*/}
              {/*  style={{ marginBottom: '1.5rem' }}*/}
              {/*/>*/}
              <IframeEmbed
                src={ep1.facebookSrc}
                width={'1280'}
                height={'411'}
                style={{
                  border: 'none',
                  overflow: 'hidden',
                }}
              />
              <Typography
                className={'font-family-secondary'}
                variant="h3"
                sx={{
                  fontSize: {
                    xs: '2rem',
                    md: '2.125rem',
                    lg: '2.5rem',
                  },
                  marginBottom: '0.5rem',
                  marginTop: '0.5rem',
                }}
              >
                {ep1.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: '0.875rem', lg: '1rem'},
                  fontStyle: 'italic',
                }}
              >
                {ep1.description}
              </Typography>
            </Box>
            <Divider
              sx={{
                color: colorSecondary,
                margin: '1.5rem 0',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: {md: '5px'},
              paddingBottom: {
                xs: '20px',
                sm: '40px',
              },
            }}
          >
            <Box>
              <Typography>
                Kopi - a drink that unites our cultures and brings Singaporeans
                from all walks of life together. The linguistics of coffee
                itself, take influence from our various cultures. To use an
                example of Kopi-o siew dai - kopi is a Malay word for coffee, o
                is a Hokkien word for dark (or black, in this context), siew dai
                is Hainanese for ‘less sweet’. By knowing the vocabulary needed
                to{' '}
                <TargetBlankLink
                  href={
                    'https://www.visitsingapore.com/editorials/order-kopi-like-a-local'
                  }
                  label={'order local coffee'}
                />
                , you can customise your order in myriad ways.
                <br/>
                <br/>
                In colonial times of Singapore, coffee was one of the top traded
                commodities, given our physical proximity to coffee-producing
                countries. Roasters would go around, churning out coffee in
                little tin drums, for independent kopitiams. To differentiate
                themselves, the respective kopitiams would come up with their
                own formulae – from adding green beans, to corn, or using
                different sugar ratios.
                <br/>
                <br/>
                It was also not unusual, for a kid in the 60s or 70s, to have
                their first sip of coffee at as young as 5. Most, as expected,
                did not appreciate the drink until they were much older. The
                nostalgia of this drink stuck, however, evident in{' '}
                <TargetBlankLink
                  href={'http://toastbox.com.sg'}
                  label={'Toast Box’s'}
                />{' '}
                interest in re-creating the taste of traditional coffee, from
                the founder’s youth. The proliferation of chain kopitiams like
                it, and places like{' '}
                <TargetBlankLink href={'http://yakun.com'} label={'Ya Kun'}/>,{' '}
                <TargetBlankLink
                  href={'https://killiney-kopitiam.com'}
                  label={'Killiney Kopitiam'}
                />{' '}
                and more, in easily-accessible areas like the Central Business
                District, and varying neighbourhoods made sure this traditional
                drink stayed popular, reaching out to new fans, and becoming a
                staple option for the coffee-drinking crowd in Singapore, even
                today.
                <br/>
                <br/>
                Elsewhere, others were reinventing the traditional drink of
                kopi. Always a fan of Singapore, and cuisine in Singapore, the
                team behind{' '}
                <TargetBlankLink
                  href={'https://www.singaporecoffee.sg'}
                  label={'Singapore Coffee'}
                />{' '}
                created an elevated experience of kopi, and the ways to present
                it, within the grand Raffles Hotel. This was kopi in a luxurious
                setting, developed in luxurious conditions, which presented a
                brand new experience with the traditional drink, for customers.
                <br/>
                <br/>
                As proof of its appeal to not just locals,{' '}
                <TargetBlankLink
                  href={'https://www.kopihouse.co.uk'}
                  label={'Kopi House UK'}
                />{' '}
                has been introducing and seducing customers from beyond our
                shores, ever since the founder came to Singapore, and discovered
                and converted to local kopi, from his regular arabica brews.
                Having not looked back since, the online store and supplier
                sells not just Nanyang kopi beans, but touts the whole Singapore
                experience of kopi. From cups, to brewing kits and socks
                commonly found in our local kopitiams, if you ever wanted to
                recreate a Singaporean coffee shop in your own home, in the UK -
                you now could.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ThumbnailButtonDialog>

      <ThumbnailButtonDialog open={dialog2Open} close={handleDialog2Close}>
        <Grid container rowSpacing={0} columnSpacing={2.5} height={`100%`}>
          <Grid
            item
            xs={12}
          >
            <Box>
              <IframeEmbed
                src={ep2.facebookSrc}
                width={'1280'}
                height={'411'}
                style={{
                  border: 'none',
                  overflow: 'hidden',
                }}
              />
              <Typography
                className={'font-family-secondary'}
                variant="h3"
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    md: '1.75rem',
                    lg: '2rem',
                    xl: '2.375rem',
                  },
                  marginBottom: '0.5rem',
                  marginTop: '0.5rem',
                }}
              >
                {ep2.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: '0.875rem', lg: '1rem'},
                  fontStyle: 'italic',
                }}
              >
                {ep2.description}
              </Typography>
            </Box>
            <Divider
              sx={{
                color: colorSecondary,
                margin: '1.5rem 0',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: {md: '5px'},
              paddingBottom: {
                xs: '20px',
                sm: '40px',
              },
            }}
          >
            <Box>
              <Typography>
                If you explored the quaint neighbourhood of Everton Park, you
                would find things like a{' '}
                <TargetBlankLink
                  href={'https://jixiangconfectionery.com.sg'}
                  label={'traditional pastry shops'}
                />
                , hip barbers, a soya bean processing shop, schools, etc. And
                somehow fitting right in with this eclectic mix, would be the
                stylish, well-regarded{' '}
                <TargetBlankLink
                  href={'https://nylon.coffee'}
                  label={'Nylon Coffee Roasters'}
                />
                . Founded by Dennis and Jia Min, who put together name elements
                of their favourite coffee destinations, New York and London,
                Nylon is known for the quality of their beans and roasts – a
                reputation that spans more than just Singapore too, with regular
                features on international coffee publications.
                <br/>
                <br/>
                Another significant coffee house –{' '}
                <TargetBlankLink
                  href={'https://cshhcoffee.com'}
                  label={'Chye Seng Huat Hardware'}
                />
                . The café with the enigmatic name is by now an institution for
                coffee lovers, situated at the{' '}
                <TargetBlankLink
                  href={
                    'https://www.visitsingapore.com/editorials/cafe-culture-in-jalan-besar/'
                  }
                  label={'Jalan Besar'}
                />{' '}
                area, and a destination in itself. With spaces to conduct
                workshops, a roastery, a fully decked out retail shop, and of
                course their iconic 360 view open bar concept, where the
                baristas go to work, one just need to follow the scent of coffee
                being roasted, to find this unique hardware store, among the
                others - special in the fact that the hardware they work with,
                roast and grind beans for coffee instead.
                <br/>
                <br/>
                Over at{' '}
                <TargetBlankLink
                  href={'https://chijmes.com.sg'}
                  label={'CHIJMES'}
                />
                , fitting right in with the architecture and lush greenery, is{' '}
                <TargetBlankLink
                  href={'https://www.facebook.com/theglasshousesg/'}
                  label={'Glasshouse'}
                />
                . Their aim of a holistic hospitality experience, is brought
                through by the use of wooden interiors, together with a number
                of plants all lovingly installed and cared for by Joshua, who
                left a career in finance to set up his dream café. Passionate
                about coffee, he thinks a great cup of coffee is about balance –
                something sweet and tasty, to be enjoyed with friends, in a
                warm, inviting environment.
                <br/>
                <br/>
                Another believer in clean, open spaces that allows for better
                interaction between customers and baristas, and focus on the
                coffees –{' '}
                <TargetBlankLink
                  href={'https://thecommunitycoffee.com'}
                  label={'The Community Coffee'}
                />
                . While their first branch in Far East Plaza had a cool,
                underground kind of vibe, with neon lights and an industrial
                touch, the new branch at Chinatown is the opposite of that. With
                an open-concept and white, clear elements, they believe that
                having everything out in the open will pique consumers’
                curiosity, and allow them to ask more questions, and in turn
                enable them to share more knowledge about the coffees they
                present.
                <br/>
                <br/>
                Singapore has no lack of specialty coffee shops, in all corners
                of town. From places like centrally located{' '}
                <TargetBlankLink
                  href={'https://www.dopadopacreamery.com'}
                  label={'Dopa Dopa'}
                />
                , which touts the best pistachio to go with your Italian coffee,
                we also have gems like{' '}
                <TargetBlankLink
                  href={'http://ciclo-e-caffe.com'}
                  label={'Ciclo e Caffe'}
                />{' '}
                tucked away in Changi Village, which is more bicycle museum than
                café. Housing rare bicycles on the café walls, such as an
                original Ernesto Colnago’s “Colnago Mexico Oro”, which is a
                bicycle crafted with a specially designed 24-carat gold plated
                tubing and a 52” sized working Penny Farthing (a.k.a. High Wheel
                bicycle) outside, as well as a small collection of original
                Singapore cycling medals.
                <br/>
                <br/>
                Rounding up the diversity of our
                coffee houses include{' '}
                <TargetBlankLink
                  href={'https://www.facebook.com/narrativecoffeestandsg/'}
                  label={'Narrative Coffee Stand'}
                />{' '}
                , nestled within the bookshops and galleries of Bras Basah
                Complex, perfect for a date, whether with a partner, or a book.
                You are only spoilt for choice at finding your perfect specialty
                coffee spot.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ThumbnailButtonDialog>

      <ThumbnailButtonDialog open={dialog3Open} close={handleDialog3Close}>
        <Grid container rowSpacing={0} columnSpacing={2.5} height={`100%`}>
          <Grid
            item
            xs={12}
          >
            <Box>
              <IframeEmbed
                src={ep3.facebookSrc}
                width={'1280'}
                height={'411'}
                style={{
                  border: 'none',
                  overflow: 'hidden',
                }}
              />
              <Typography
                className={'font-family-secondary'}
                variant="h3"
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    md: '1.75rem',
                    lg: '2rem',
                    xl: '2.375rem',
                  },
                  marginBottom: '0.5rem',
                  marginTop: '0.5rem',
                }}
              >
                {ep3.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: '0.875rem', lg: '1rem'},
                  fontStyle: 'italic',
                }}
              >
                {ep3.description}
              </Typography>
            </Box>
            <Divider
              sx={{
                color: colorSecondary,
                margin: '1.5rem 0',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: {md: '5px'},
              paddingBottom: {
                xs: '20px',
                sm: '40px',
              },
            }}
          >
            <Box>
              <Typography>
                When is coffee, not just coffee? When it is a social enterprise,
                like at{' '}
                <TargetBlankLink
                  href={'https://forewordcoffee.com'}
                  label={'Foreword Coffee'}
                />
                , who hires persons with disabilities, and puts them in various
                positions within a café structure. Starting from cleaning and
                cashier duties, the pride these hires take in achieving a
                barista status is palatable, in the dedication and focus they
                take, in putting down final touches to their coffees. The items
                they sell in the stores have a similar socially conscious ethos
                – from reusable coffee cups made up of recycled coffee husk, to
                beans imported from smaller, coffee producing farms around the
                ASEAN region. Their goal is to put as many jobs as possible
                within the reach of less-served sectors of the community at
                large.
                <br/>
                <br/>
                Training is a common theme still, between this social
                enterprise, and the coffee houses around. Passion for the art,
                and training go hand-in-hand at{' '}
                <TargetBlankLink
                  href={'https://www.tionghoe.com'}
                  label={'Tiong Hoe Specialty Coffee'}
                />
                , a company that evolved from a three-generation coffee family,
                starting in the fifties till today, where it occupies the
                original operational office at Stirling Road. Co-founder Jacob,
                is one of the rare few that hold the title of a{' '}
                <TargetBlankLink
                  href={
                    'https://www.coffeeinstitute.org/our-work/a_common_language/what-is-a-q-grader/'
                  }
                  label={'Q Arabica Grader'}
                />
                . This is an advanced level course, held by the Coffee Quality
                Institute, and internationally recognized. Due to its very
                strict testing protocols, it has a less than 50% pass rate, and
                has been even regarded as a lawyer’s bar exam equivalent, for
                baristas.
                <br/>
                <br/>
                This has also seen him qualified to judge several barista
                competitions, both locally, and abroad. A little-known fact
                about Singapore’s coffee industry, is that there are several
                world champions finalists among our midst. And pre-pandemic, a
                healthy local competition scene, consisting of 5 key{' '}
                <TargetBlankLink
                  href={'https://singaporecoffee.org/page-18135'}
                  label={'competitions'}
                />{' '}
                – Singapore National Barista Championships, Latte Art, Brewer’s
                Cup, Taster’s Cup, and Coffee in Good Spirits.
                <br/>
                <br/>
                Some competition winners who have translated their skills into
                impressing customers, include Rodman and Andrea of{' '}
                <TargetBlankLink
                  href={'https://www.facebook.com/alucidspace/'}
                  label={'Lucid'}
                />
                . Whose stark, neutral coffee space is specially made so, in
                order to let customers focus on what they are eating and
                drinking more. Other award-winning baristas behind coffee
                houses, include{' '}
                <TargetBlankLink
                  href={'https://apartmentcoffee.co'}
                  label={'Apartment Coffee'}
                />
                ,{' '}
                <TargetBlankLink
                  href={'https://www.strangersreunion.com'}
                  label={'Strangers’ Reunion'}
                />{' '}
                and more.
                <br/>
                <br/>
                And with the innovation and evolution of technology, some are
                trying to capture the skills and technique of these passionate
                baristas, to make sure consistently good coffee is always
                available. Crown Digital, with their years of experienced in the
                F&B industry, wanted to find a way to make up for the manpower
                gap, and at the same time provide consistent, convenient coffees
                to the public. Hence{' '}
                <TargetBlankLink
                  href={'https://www.crowndigital.io'}
                  label={'Ella'}
                />{' '}
                was born, a robotic barista that fit right in with Singapore’s
                fast moving crowds, and getting coffees to those who needed it,
                with minimum fuss. With{' '}
                <TargetBlankLink
                  href={
                    'https://www.crowndigital.io/post/stellarlifestyle-crowndigitaltobringrobotbaristaellato30mrtstations'
                  }
                  label={'plans'}
                />{' '}
                to be serving gourmet coffee to some 30 MRT stations across
                Singapore, by end 2022, the current machine at Plaza Singapura,
                is only the beginning.
                <br/>
                <br/>
                Further down along Orchard Road,{' '}
                <TargetBlankLink
                  href={'https://ratio.inc'}
                  label={'Ratio'}
                />{' '}
                also serves up specialty coffee by means of their robotic
                barista, and also traditional coffees, and coffee cocktails.
                Working together with Tiong Hoe Specialty Coffee, by using
                motion sensors on their baristas to mimic the movements, and
                other intricacies of coffee-making, they have big plans in
                store, including coming up with a robotic barista capable of
                latte art – something that would be a world first in the market.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ThumbnailButtonDialog>
    </React.Fragment>
  );
};

export default PageIndex;
