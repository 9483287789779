import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PlayButton from '../images/play-button.inline.svg';

const ThumbnailButton = ({image, onClick, children, title, larger}) => {
  const [isHover, setHover] = React.useState(false);

  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const defaultWidth = {xs: '60vw', sm: '190px', md: '170px', lg: '255px', xl: '360px'};
  const largerWidth = {xs: '73vw', sm: '275px', md: '200px', lg: '300px', xl: '420px'};

  return (
    <React.Fragment>
      <Box
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          position: 'relative',
          background: `url(${image})`,
          backgroundSize: 'cover',
          '&::before': {
            content: `''`,
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            height: '0',
            paddingTop: '56.25%',
          },
          '&::after': {
            content: `''`,
            display: 'block',
            clear: 'both',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: `2px solid ${colorSecondary}`,
            opacity: 0,
          },
          '&:hover::after': {
            opacity: 1,
          },
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // width: {
          //   xs: '60vw',
          //   sm: 'auto',
          // },
          width: larger === true ? largerWidth : defaultWidth,
          marginBottom: {
            xs: '0.75rem',
            sm: '1rem',
          },
        }}
      >
        <PlayButton fill={isHover ? colorSecondary : 'white'}/>
      </Box>
      <Box
        sx={{
          width: larger === true ? largerWidth : defaultWidth,
        }}
      >
        {title &&
          <Typography className={'font-family-secondary'} color={isHover ? colorSecondary : 'white'} fontWeight="bold"
                      fontSize={{
                        xs: '1.25rem',
                        lg: '1.5rem',
                      }}>
            {title}
          </Typography>
        }
        {children}
      </Box>
    </React.Fragment>
  )
    ;
};

export default ThumbnailButton;
