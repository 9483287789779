import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import kopi from '../images/kopi/kopi.png';
import kopiC from '../images/kopi/kopi-c.png';
import kopiDiLo from '../images/kopi/kopi-di-lo.png';
import kopiGahDai from '../images/kopi/kopi-gah-dai.png';
import kopiGau from '../images/kopi/kopi-gau.png';
import kopiO from '../images/kopi/kopi-o.png';
import kopiOKosong from '../images/kopi/kopi-o-kosong.png';
import kopiPok from '../images/kopi/kopi-pok.png';
import kopiSiewDai from '../images/kopi/kopi-siew-dai.png';
import kopiPeng from '../images/kopi/kopi-peng.png';
import cdCondensedMilk from '../images/kopi/cd-condensed-milk.svg';
import cdEvaporatedMilk from '../images/kopi/cd-evaporated-milk.svg';
import cdKopiO from '../images/kopi/cd-kopi-o.svg';
import cdSugar from '../images/kopi/cd-sugar.svg';
import cdIce from '../images/kopi/cd-ice.svg';

const KopiCard = ({ type, innerRef }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const handleMouseEnter = () => {
    const pageSlider = document.getElementById('page-slider');
    pageSlider.swiper.mousewheel.disable();
  };

  const handleMouseLeave = () => {
    const pageSlider = document.getElementById('page-slider');
    pageSlider.swiper.mousewheel.enable();
  };

  const sideStyle = {
    borderRadius: '4px',
    border: `2px solid ${colorSecondary}`,
    boxShadow: 'none',
    padding: 0,
    width: '100%',
    height: '100%',
  };

  const titleFontSize = {
    xs: '1.75rem',
    lg: '2.25rem',
  };

  const componentCondensedMilk = {
    image: cdCondensedMilk,
    text: 'Condensed Milk',
  };

  const componentLessCondensedMilk = {
    image: cdCondensedMilk,
    text: 'Less Condensed Milk',
  };

  const componentMoreCondensedMilk = {
    image: cdCondensedMilk,
    text: 'More Condensed Milk',
  };

  const componentEvaporatedMilk = {
    image: cdEvaporatedMilk,
    text: 'Evaporated Milk',
  };

  const componentKopiO = {
    image: cdKopiO,
    text: 'Kopi-O',
  };

  const componentLessKopiO = {
    image: cdKopiO,
    text: 'Less Kopi-O',
  };

  const componentMoreKopiO = {
    image: cdKopiO,
    text: 'More Kopi-O',
  };

  const componentSugar = {
    image: cdSugar,
    text: 'Sugar',
  };

  const componentIce = {
    image: cdIce,
    text: 'Ice',
  };

  const coffeeType = {
    kopi: {
      frontImage: kopi,
      counter: '01/09',
      title: 'KOPI',
      description: `
        The O.G.
      `,
      components: [
        componentKopiO,
        componentCondensedMilk,
      ],
    },
    kopiC: {
      frontImage: kopiC,
      counter: '02/09',
      title: 'KOPI-C',
      description: `
        When you don't like sweet milk.
      `,
      components: [
        componentKopiO,
        componentEvaporatedMilk,
        componentSugar,
      ],
    },
    kopiSiewDai: {
      frontImage: kopiSiewDai,
      counter: '03/09',
      title: 'KOPI SIEW DAI',
      description: `
        You like sweet milk, but feel guilty about it.
      `,
      components: [
        componentKopiO,
        componentLessCondensedMilk,
      ],
    },
    kopiOKosong: {
      frontImage: kopiOKosong,
      counter: '04/09',
      title: 'KOPI O KOSONG',
      description: `
        Functional black.
      `,
      components: [
        componentKopiO,
      ],
    },
    kopiGau: {
      frontImage: kopiGau,
      counter: '05/09',
      title: 'KOPI GAU',
      description: `
        Extra caffeine for sleepy days.
      `,
      components: [
        componentMoreKopiO,
        componentCondensedMilk,
      ],
    },
    kopiGahDai: {
      frontImage: kopiGahDai,
      counter: '06/09',
      title: 'KOPI GAH DAI',
      description: `
        You have no qualms about liking sweet milk.
      `,
      components: [
        componentKopiO,
        componentMoreCondensedMilk,
      ],
    },
    kopiO: {
      frontImage: kopiO,
      counter: '07/09',
      title: 'KOPI-O',
      description: `
        More of a fun black.
      `,
      components: [
        componentKopiO,
        componentSugar,
      ],
    },
    kopiPok: {
      frontImage: kopiPok,
      counter: '08/09',
      title: 'KOPI POK',
      description: `
        For weaklings.
      `,
      components: [
        componentLessKopiO,
        componentCondensedMilk,
      ],
    },
    kopiPeng: {
      frontImage: kopiPeng,
      counter: '09/09',
      title: 'KOPI PENG',
      description: `
        For hot days.
      `,
      components: [
        componentKopiO,
        componentCondensedMilk,
        componentIce,
      ],
    },
  };

  return (
    <Box
      sx={{
        width: {
          xs: '73vmin',
          sm: '50vmin',
          md: '23vw',
          lg: '20vw',
          xl: '19vw',
        },
        height: {
          xs: '54vmax',
          sm: '50vmax',
          md: '40vh',
          lg: '45vh',
          xl: '44vh',
        },
        maxWidth: {
          xs: '275px',
          sm: '300px',
          md: '235px',
          lg: '350px',
          xl: '360px',
        },
        maxHeight: {
          xs: '360px',
          sm: '400px',
          md: '310px',
          lg: '465px',
          xl: '480px',
        },
        // 54/73 = 44/x
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      ref={innerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flippy
        flipOnHover={true} // default false
        flipOnClick={true} // default false
        flipDirection="horizontal" // horizontal or vertical
        // ref={ref} // to use toggle method like ref.current.toggle()
        // if you pass isFlipped prop component will be controlled component.
        // and other props, which will go to div
        style={{ width: '100%', height: '100%' }} // these are optional style, it is not necessary
      >
        <FrontSide
          animationDuration={600}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            ...sideStyle,
          }}
        >
          {/*{frontSide}*/}
          <Box
            textAlign={'center'}
            padding={{ xs: '20px', lg: '40px 24px', xl: '40px 30px' }}
            width={'100%'}
            height={'100%'}
          >
            <img
              src={coffeeType[type].frontImage}
              alt={coffeeType[type].title}
              style={{ width: 'auto', maxWidth: '100%', maxHeight: '70%' }}
            />
            {/*<Typography*/}
            {/*  color={colorSecondary}*/}
            {/*  fontSize={{ xs: '1rem', lg: '1.25rem' }}*/}
            {/*>*/}
            {/*  {coffeeType[type].counter}*/}
            {/*</Typography>*/}
            <Typography
              className={'font-family-secondary'}
              color={colorPrimary}
              fontSize={titleFontSize}
              fontWeight={'bold'}
              marginTop={'24px'}
            >
              {coffeeType[type].title}
            </Typography>
          </Box>
        </FrontSide>
        <BackSide
          animationDuration={600}
          style={{
            backgroundColor: colorSecondary,
            color: 'white',
            ...sideStyle,
          }}
        >
          {/*{backSide}*/}
          <Box
            color={'#ffffff'}
            padding={{ xs: '20px 12px', lg: '40px 20px' }}
            width={'100%'}
            height={'100%'}
            sx={{
              overflowY: 'auto',
            }}
          >
            <Typography
              className={'font-family-secondary'}
              fontSize={titleFontSize}
              fontWeight={'bold'}
              textAlign={'center'}
              lineHeight={1}
            >
              {coffeeType[type].title}
            </Typography>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.2)',
                color: 'white',
                marginTop: { xs: '8px', md: '3px', lg: '17px' },
                marginBottom: { xs: '20px', md: '12px', lg: '24px' },
              }}
            />
            <Grid
              container
              rowSpacing={0}
              columnSpacing={0.5}
              textAlign={'center'}
            >
              {coffeeType[type].components.map((item, index) => (
                <Grid
                  item
                  key={item.text}
                  xs={4}
                  textAlign={'center'}
                  marginLeft={index === 0 ? 'auto' : 0}
                  marginRight={
                    index === coffeeType[type].components.length - 1
                      ? 'auto'
                      : 0
                  }
                >
                  <Box
                    height={{
                      xs: '50px',
                      md: '40px',
                      lg: '60px',
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.text}
                      style={{ width: 'auto', height: '100%' }}
                    />
                  </Box>
                  <Typography fontSize={{ xs: '0.875rem', lg: '1rem' }} lineHeight={1.125}>
                    {item.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.2)',
                color: 'white',
                marginTop: { xs: '20px', md: '10px', lg: '20px' },
                marginBottom: { xs: '20px', md: '10px', lg: '20px' },
              }}
            />
            <Typography fontSize={{ xs: '0.875rem', lg: '1rem' }} textAlign={'center'}>
              {coffeeType[type].description}
            </Typography>
          </Box>
        </BackSide>
      </Flippy>
    </Box>
  );
};

export default KopiCard;
