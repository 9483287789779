import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import PrevButton from './PrevButton';
import NextButton from './NextButton';
import TargetBlankLink from './TargetBlankLink';
import TimelineSlide from './TimelineSlide';
import TimelineDialog from './TimelineDialog';

import bgDialog from '../images/bg-dialog.png';
import bgWave1Mobile from '../images/bg-wave1-mobile.png';
import bgWave2Mobile from '../images/bg-wave2-mobile.png';
import bgWave3Mobile from '../images/bg-wave3-mobile.png';
import bgWave4Mobile from '../images/bg-wave4-mobile.png';
import bgWave1Desktop from '../images/bg-wave1-desktop.png';
import bgWave2Desktop from '../images/bg-wave2-desktop.png';
import bgWave3Desktop from '../images/bg-wave3-desktop.png';
import bgWave4Desktop from '../images/bg-wave4-desktop.png';
import TimelineDot from '../images/timeline-dot.inline.svg';
import wave1Img1 from '../images/wave1/kopi-set.jpg';
import wave1Img2 from '../images/wave1/NAS-kopitiam-in-the-60s.jpg';
import wave1Img3 from '../images/wave1/nescafe-instant-coffee.jpg';
import wave1Img4 from '../images/wave1/toast-box.jpg';
import wave2Img1 from '../images/wave2/o_coffee-club.jpg';
import wave2Img2 from '../images/wave2/starbucks.jpg';
import wave2Img3 from '../images/wave2/barista-at-work.jpg';
import wave3Img1 from '../images/wave3/highlander-coffee.png';
import wave3Img2 from '../images/wave3/barista.jpg';
import wave3Img3 from '../images/wave3/coffee-roasting.jpg';

const PageTimeline = () => {
  const timelineSwiper = React.useRef(null);
  const wave1 = React.useRef(null);
  const wave2 = React.useRef(null);
  const wave3 = React.useRef(null);
  const wave4 = React.useRef(null);
  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [dialog3Open, setDialog3Open] = React.useState(false);
  const [dialog4Open, setDialog4Open] = React.useState(false);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            colorPrimary
            colorSecondary
          }
        }
      }
    `
  );

  const colorPrimary = site.siteMetadata.colorPrimary;
  const colorSecondary = site.siteMetadata.colorSecondary;

  const wave1Title = `WAVE 1: FUNCTIONALITY`;
  const wave1Description = `From commodity, to necessity`;
  const wave2Title = `WAVE 2: ENJOYMENT`;
  const wave2Description = `The rise of the Third Space`;
  const wave3Title = `WAVE 3: LOVE`;
  const wave3Description = `Passionate people, crafting artisan coffee`;
  // const wave4Title = `WAVE 4: SCIENCE`;
  // const wave4Description = `Elevated coffee making, using techy tools, precise measurements, and also more focus on sustainability.`;

  const handlePrevButton = () => {
    timelineSwiper.current.swiper.slidePrev();
  };

  const handleNextButton = () => {
    timelineSwiper.current.swiper.slideNext();
  };

  const handleSlideChange = () => {
    wave1.current.classList.remove('active');
    wave2.current.classList.remove('active');
    wave3.current.classList.remove('active');
    // wave4.current.classList.remove('active');

    if (timelineSwiper.current.swiper.realIndex === 0) {
      wave1.current.classList.add('active');
    } else if (timelineSwiper.current.swiper.realIndex === 1) {
      wave2.current.classList.add('active');
    } else if (timelineSwiper.current.swiper.realIndex === 2) {
      wave3.current.classList.add('active');
    } /*else if (timelineSwiper.current.swiper.realIndex === 3) {
      wave4.current.classList.add('active');
    }*/
  };

  const handleWaveClick = event => {
    const slideNumber = parseInt(event.target.getAttribute('data-slide'), 10);
    if (timelineSwiper.current.swiper) {
      timelineSwiper.current.swiper.slideToLoop(slideNumber);
    }
  };

  const imageCreditStyle = {
    fontSize: '75%',
    fontStyle: 'italic',
    display: 'block',
    textAlign: 'center',
    marginTop: '5px',
  };

  const handleDialog1Open = () => {
    setDialog1Open(true);
  };

  const handleDialog1Close = () => {
    setDialog1Open(false);
  };

  const handleDialog2Open = () => {
    setDialog2Open(true);
  };

  const handleDialog2Close = () => {
    setDialog2Open(false);
  };

  const handleDialog3Open = () => {
    setDialog3Open(true);
  };

  const handleDialog3Close = () => {
    setDialog3Open(false);
  };

  const handleDialog4Open = () => {
    setDialog4Open(true);
  };

  const handleDialog4Close = () => {
    setDialog4Open(false);
  };

  return (
    <React.Fragment>
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          boxSizing: 'border-box',
          background: `url(${bgDialog})`,
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        <Box
          width={{ xs: '25%', md: '34%', lg: '30%', xl: '25%' }}
          sx={{
            marginTop: {
              xs: '75px',
              md: '80px',
              lg: '100px',
            },
            paddingRight: {
              xs: '20px',
              md: '60px',
              xl: '80px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            className={'font-family-secondary'}
            color={colorSecondary}
            fontSize={{
              xs: '2.375rem',
              md: '3.25rem',
              lg: '3.5rem',
              xl: '3.75rem',
            }}
            sx={{
              opacity: 0.2,
              position: {
                xs: 'absolute',
                sm: 'relative',
              },
              left: {
                xs: '20px',
                sm: 'auto',
              },
              top: {
                xs: '15px',
                sm: 'auto',
              },
            }}
          >
            What's in a Wave?
          </Typography>
          <Box display={'inline-block'} marginTop={{ xs: '20px', sm: 0 }}>
            <Box
              className={'wave-number-wrapper'}
              display={'inline-block'}
              position={'relative'}
            >
              <Box className={`wave-number`} ref={wave1}>
                <Typography
                  data-slide={0}
                  onClick={handleWaveClick}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  WAVE 1
                </Typography>
                <Box className={'timeline-dot'}>
                  <TimelineDot />
                </Box>
              </Box>
              <Box className={'wave-number'} ref={wave2}>
                <Typography
                  data-slide={1}
                  onClick={handleWaveClick}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  WAVE 2
                </Typography>
                <Box className={'timeline-dot'}>
                  <TimelineDot />
                </Box>
              </Box>
              <Box className={'wave-number'} ref={wave3}>
                <Typography
                  data-slide={2}
                  onClick={handleWaveClick}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  WAVE 3
                </Typography>
                <Box className={'timeline-dot'}>
                  <TimelineDot />
                </Box>
              </Box>
              {/*<Box className={'wave-number'} ref={wave4}>*/}
              {/*  <Typography*/}
              {/*    data-slide={3}*/}
              {/*    onClick={handleWaveClick}*/}
              {/*    sx={{*/}
              {/*      cursor: 'pointer',*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    WAVE 4*/}
              {/*  </Typography>*/}
              {/*  <Box className={'timeline-dot'}>*/}
              {/*    <TimelineDot />*/}
              {/*  </Box>*/}
              {/*</Box>*/}
              {/*<Divider*/}
              {/*  orientation={'vertical'}*/}
              {/*  sx={{*/}
              {/*    borderColor: colorSecondary,*/}
              {/*    display: 'block',*/}
              {/*    zIndex: 1,*/}
              {/*    position: 'absolute',*/}
              {/*    right: '8px',*/}
              {/*    top: {*/}
              {/*      xs: '7px',*/}
              {/*      md: '15px',*/}
              {/*    },*/}
              {/*    minHeight: {*/}
              {/*      xs: 'calc(100% - 14px)',*/}
              {/*      md: 'calc(100% - 30px)',*/}
              {/*    },*/}
              {/*    maxHeight: {*/}
              {/*      xs: 'calc(100% - 14px)',*/}
              {/*      md: 'calc(100% - 30px)',*/}
              {/*    },*/}
              {/*    height: {*/}
              {/*      xs: '95%',*/}
              {/*      md: '88%',*/}
              {/*    },*/}
              {/*  }}*/}
              {/*/>*/}
              <Box
                sx={{
                  borderRight: '1px solid',
                  borderColor: colorSecondary,
                  display: 'block',
                  zIndex: 1,
                  position: 'absolute',
                  right: '8px',
                  top: {
                    xs: '7px',
                    md: '15px',
                  },
                  height: {
                    xs: 'calc(100% - 14px)',
                    md: 'calc(100% - 30px)',
                  },
                }}
              >
                &nbsp;
              </Box>
            </Box>
            <Box
              marginTop={{
                xs: '15vh',
                md: '8vh',
                lg: '8vh',
                xl: '15vh',
              }}
              textAlign={'right'}
            >
              <PrevButton
                handleClick={handlePrevButton}
                isVertical={true}
                style={{ display: 'inline-block' }}
              />
              <br />
              <NextButton
                handleClick={handleNextButton}
                isVertical={true}
                style={{ display: 'inline-block' }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          width={{ xs: '75%', md: '66%', lg: '70%', xl: '75%' }}
          sx={{
            marginTop: {
              xs: '75px',
              md: '80px',
              lg: '100px',
            },
            overflow: 'hidden',
          }}
        >
          <Swiper
            direction={'vertical'}
            spaceBetween={20}
            slidesPerView="auto"
            loop={true}
            loopedSlides={3}
            centeredSlides={true}
            allowTouchMove={false}
            ref={timelineSwiper}
            breakpoints={{
              900: {
                spaceBetween: 40,
              },
            }}
            onSlideChange={handleSlideChange}
            id="timeline-slider"
          >
            <SwiperSlide>
              <TimelineSlide
                bgMobile={bgWave1Mobile}
                bgDesktop={bgWave1Desktop}
                overlayColor={'rgba(194, 136, 66, 0.8)'}
                title={wave1Title}
                description={wave1Description}
                buttonClick={handleDialog1Open}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TimelineSlide
                bgMobile={bgWave2Mobile}
                bgDesktop={bgWave2Desktop}
                overlayColor={'rgba(177, 129, 98, 0.8)'}
                title={wave2Title}
                description={wave2Description}
                buttonClick={handleDialog2Open}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TimelineSlide
                bgMobile={bgWave3Mobile}
                bgDesktop={bgWave3Desktop}
                overlayColor={'rgba(134, 89, 58, 0.8)'}
                title={wave3Title}
                description={wave3Description}
                buttonClick={handleDialog3Open}
              />
            </SwiperSlide>
            {/*<SwiperSlide>*/}
            {/*  <TimelineSlide*/}
            {/*    bgMobile={bgWave4Mobile}*/}
            {/*    bgDesktop={bgWave4Desktop}*/}
            {/*    overlayColor={'rgba(62, 32, 11, 0.8)'}*/}
            {/*    title={wave4Title}*/}
            {/*    description={wave4Description}*/}
            {/*    buttonClick={handleDialog4Open}*/}
            {/*  />*/}
            {/*</SwiperSlide>*/}
          </Swiper>
        </Box>
      </Container>
      <TimelineDialog open={dialog1Open} close={handleDialog1Close}>
        <Box color={colorPrimary}>
          <Typography
            className={'font-family-secondary'}
            variant="h3"
            fontSize={{
              xs: '2rem',
              md: '2.125rem',
              lg: '2.5rem',
            }}
            marginBottom={'1.5rem'}
          >
            {wave1Title}
          </Typography>
          <Typography>
            With the opening up of Singapore in the 19th century, came along the
            Europeans, and together with them their love for coffee. The
            necessity to keep supply steady with lower-cost Robusta beans, more
            easily available from neighbouring countries, seemed like a natural
            progression to the quick adaptation of practical torrefaction – a
            method of roasting coffee beans with sugar, that would enhance the
            taste characteristics of this cheaper bean, as well as keep the
            coffee fresh for longer. Enterprising early immigrants from Hainan,
            opened up coffee shops, and further improved on the taste, by
            grinding and straining the beans through a sock, and mixing it with
            varying amounts of hot water, condensed milk and sugar.
            <br />
            <br />
            This traditional coffee, or ‘kopi’, was soon embraced by the locals.
            Who welcomed the <strong>functional</strong> aspect of coffee, in
            terms of the quick energy it provided. Thus came about the first
            wave of coffee in Singapore, and the birth of the kopitiam culture
            here.
            <br />
            <br />
            <img src={wave1Img1} alt="Kopi set" />
            <br />
            Although the coffee was functional, kopitiams became significant
            gathering places for the local public, and rapidly became important
            venues that facilitated the growth of civil society. An easily
            accessible space for people from different cultures, races, and
            beliefs to gather, it became one of the key institutions that grew
            the Singaporean multiculturalism, that carries on even today.
            <br />
            <br />
            <img src={wave1Img2} alt="Kopitiam in the 60s" />
            <span style={imageCreditStyle}>
              image credit: Courtesy of National Archives of Singapore
            </span>
            <br />
            The 60s and 70s saw coffee squarely ingrained as an everyday
            necessity for a large part of the population. With easily available
            supermarket brands like Boncafé, Suzuki Coffee, and Nescafe, the
            first wave of coffee was easily accessible now to all.
            <br />
            <img src={wave1Img3} alt="Instant coffee" />
            <span style={imageCreditStyle}>
              image credit:{' '}
              <TargetBlankLink
                href={'https://thenescafeway.wordpress.com'}
                label={'thenescafeway.wordpress.com'}
              />
            </span>
            <br />
            By the 90’s, new generations managing original kopitiams saw the
            opportunity in opening up chain traditional / Nanyang coffee stalls
            – namely Killiney Kopitiam, Ya Kun Kaya Toast, and Toast Box. While
            the ritual of having a kopi, together with the accompanying
            ‘traditional’ breakfast of toast and half-boiled eggs solidified
            held, they all developed their own unique brews of coffee – creating
            brand names with their own style.
            <br />
            <br />
            <img src={wave1Img4} alt="Toast box" />
            <br />
            Coffee was becoming more than just functional. It was growing to
            become an experience.
            <br />
            <br />
            <span
              style={{
                fontSize: '90%',
                overflowWrap: 'break-word',
                fontStyle: 'italic',
                color: 'rgba(47, 47, 47, 0.5)',
              }}
            >
              <TargetBlankLink
                href={
                  'https://www.researchgate.net/publication/228196528_The_Kopitiam_in_Singapore_An_Evolving_Story_about_Migration_and_Cultural_Diversity'
                }
              />
              <br />
              <TargetBlankLink
                href={
                  'https://perfectdailygrind.com/2021/08/understanding-the-singaporean-coffee-market/'
                }
              />
              <br />
              <TargetBlankLink
                href={
                  'https://ap5.fas.nus.edu.sg/fass/mlsasmk/coffee-shops%20in%20colonial%20singapore:%20domains%20of%20contentious%20publics.pdf'
                }
              />
            </span>
          </Typography>
        </Box>
      </TimelineDialog>
      <TimelineDialog open={dialog2Open} close={handleDialog2Close}>
        <Box color={colorPrimary}>
          <Typography
            className={'font-family-secondary'}
            variant="h3"
            fontSize={{
              xs: '2rem',
              md: '2.125rem',
              lg: '2.5rem',
            }}
            marginBottom={'1.5rem'}
          >
            {wave2Title}
          </Typography>
          <Typography>
            As palettes developed, consumers wanted more than just functional
            coffee. By the early 90s, there were already all types of coffee
            from several parts of the world available in the local market, both
            in instant or soluble form, or found at high-end cafes. Consumers
            were growing more discerning, and the demand for better tasting
            coffee was on the rise.
            <br />
            <br />
            In 1991, O’Coffee Club opened in Holland Village, becoming
            Singapore’s first gourmet coffee house, with a roasting facility
            located within the outlet. Created to be a home-away-from-home
            space, this was modeled after the growing trend of the “Third Space”
            coffee house – an icon of the Second Wave of coffee. Here was where
            customers could enjoy a coffee they like, at varying levels of
            customisation, and use it as a place to meet friends, catch up with
            people, or simply be with themselves for a little bit.
            <br />
            <br />
            <img src={wave2Img1} alt="O'CoffeeClub Singapore" />
            <span style={imageCreditStyle}>
              image credit:{' '}
              <TargetBlankLink
                href={
                  'https://www.facebook.com/OCoffeeClub.Singapore/posts/the-first-o-coffee-club-started-in-singapore-in-the-heart-of-holland-village-in-/3125109254210271/'
                }
                label={`O'Coffee Club Facebook`}
              />
            </span>
            <br />
            Bigger, overseas chains shortly followed, with Spinelli Coffee,
            Starbucks, as well as Coffee Bean & Tea Leaf entering the local
            market in 1996. The layouts of these second wave coffee houses were
            all designed to be as cosy and homey and possible, and encouraged
            customers to linger. Hence, the second wave of coffee, was defined
            by the <strong>enjoyment</strong> of coffee.
            <br />
            <br />
            <img src={wave2Img2} alt="Starbucks Singapore" />
            <span style={imageCreditStyle}>
              image credit:{' '}
              <TargetBlankLink
                href={'https://www.facebook.com/StarbucksSingapore'}
                label={`Starbucks Singapore Facebook`}
              />
            </span>
            <br />
            The coffee places experimented with different roasts, and introduced
            consumers to various types of coffees. This period was where
            Singaporeans got introduced to Frappuccinos, or other ice-blended
            drinks – a very well-received variation of coffee, in our sunny
            locale. Still, during this second wave, the coffee was still almost
            incidental. What was defining of this wave, was often more the
            social activity of visiting these types of places. Furthermore, the
            systems and training at these kinds of places, encouraged budding
            baristas, to start seriously considering making coffee a career.
            <br />
            <br />
            <img src={wave2Img3} alt="Barista at work" />
            <br />
            Consumers were also now more curious about different roasts, methods
            of brewing coffee, and ways of having coffee. An awakening curiosity
            about coffee had started to take hold.
            <br />
            <br />
            <span
              style={{
                fontSize: '90%',
                overflowWrap: 'break-word',
                fontStyle: 'italic',
                color: 'rgba(47, 47, 47, 0.5)',
              }}
            >
              <TargetBlankLink
                href={'https://www.ocoffeeclub.com/about/about-us.html'}
              />
              <br />
              <TargetBlankLink href={'https://spinellicoffee.com/about-us/'} />
              <br />
              <TargetBlankLink
                href={
                  'https://www.facebook.com/OCoffeeClub.Singapore/posts/the-first-o-coffee-club-started-in-singapore-in-the-heart-of-holland-village-in-/3125109254210271/'
                }
              />
              <br />
              <TargetBlankLink
                href={
                  'https://www.starbucks.com.sg/about-us/starbucks-singapore'
                }
              />
              <br />
              <TargetBlankLink
                href={
                  'https://www.luckybelly.com/first-second-third-wave-coffee/'
                }
              />
              <br />
              2002 Flamekeeper “Norway and Coffee”By: Trish R Skeie
              <br />
              <TargetBlankLink
                href={
                  'https://www.nytimes.com/2000/04/14/business/wake-up-and-smell-the-profits-a-singaporean-sees-the-world-as-his-coffee-cup.html'
                }
              />
            </span>
          </Typography>
        </Box>
      </TimelineDialog>
      <TimelineDialog open={dialog3Open} close={handleDialog3Close}>
        <Box color={colorPrimary}>
          <Typography
            className={'font-family-secondary'}
            variant="h3"
            fontSize={{
              xs: '2rem',
              md: '2.125rem',
              lg: '2.5rem',
            }}
            marginBottom={'1.5rem'}
          >
            {wave3Title}
          </Typography>
          <Typography>
            While the first wave of coffee was functional, and the second wave
            about enjoyment, the third wave was touted to be about{' '}
            <strong>appreciation</strong>. In Singapore, Highlander Coffee was
            one of the first few champions of specialty coffee, by conducting
            specialty coffee appreciation workshops, courses, cumulating in
            having people enjoy and experience specialty coffee live, at the
            Highlander Espresso Bar, which opened in 2006. This was arguably
            Singapore’s first specialty coffee house.
            <br />
            <br />
            <img src={wave3Img1} alt="Highlander Coffee" />
            <span style={imageCreditStyle}>
              image credit:{' '}
              <TargetBlankLink
                href={'https://www.highlandercoffee.com/'}
                label={`highlandercoffee.com`}
              />
            </span>
            <br />
            Other players were shortly also on the scene – transitioning from
            experimental or temporary sites, into more permanent location. Papa
            Palheta, operating out of a hole-in-the-wall at Hooper road,
            experimented with various single origin beans, letting customers
            sample the brews, before buying them home for their own consumption.
            Gradually expanding to Chye Seng Huat Hardware at Jalan Besar – now
            a specialty coffee café icon. Nylon Coffee Roasters, with an
            international reputation for curated, well-sourced beans, found a
            space in quaint Everton Park. Tiong Hoe Specialty Coffee revamped
            their original space at Stirling Road, a traditional coffee roaster
            since the 60s, and enlivened the aging estate. Glasshouse, converted
            from a temp container space, to a permanent one at CHIJMES.
            <br />
            <br />
            <img src={wave3Img2} alt="Barista brewing coffee" />
            <span style={imageCreditStyle}>
              image credit:{' '}
              <TargetBlankLink
                href={'https://www.facebook.com/nyloncoffeeroasters'}
                label={`Nylon Coffee Roasters Facebook`}
              />
            </span>
            <br />
            Roasting of coffee on premise became more commonplace, and everyone
            had their own best formula. The vocabulary describing coffee had
            grown – adjectives usually seen on food or wine menus, now came with
            your coffee menus, to describe how your single origin bean would
            taste like. Presentation of the coffees also developed, with new and
            more intricate latte art adorning each coffee, and those baristas’
            skills grew as well.
            <br />
            <br />
            <img src={wave3Img3} alt="Coffee Roasting" />
            <br />
            The third wave of coffee was indisputably here. Appreciation of
            coffee sparked curiosity about it, and the love people put into
            coffee-making now, was evident. From the latte art champions, to
            home brewers, who were equipped now both in knowledge and in
            equipment, to recreate the brews they loved in the comfort of their
            homes, coffee had now become a passion for some Singaporeans.
            <br />
            <br />
            <span
              style={{
                fontSize: '90%',
                overflowWrap: 'break-word',
                fontStyle: 'italic',
                color: 'rgba(47, 47, 47, 0.5)',
              }}
            >
              <TargetBlankLink
                href={
                  'https://perfectdailygrind.com/2017/04/what-is-third-wave-coffee-how-is-it-different-to-specialty/'
                }
              />
              <br />
              <TargetBlankLink
                href={
                  'https://www.drivencoffee.com/blog/coffee-waves-explained/'
                }
              />
              <br />
              <TargetBlankLink href={'http://thebrandguide.com/journal/435/'} />
              <br />
              <TargetBlankLink
                href={'https://www.highlandercoffee.com/about/'}
              />
              <br />
              <TargetBlankLink
                href={
                  'https://perfectdailygrind.com/2021/08/understanding-the-singaporean-coffee-market/'
                }
              />
              <br />
            </span>
          </Typography>
        </Box>
      </TimelineDialog>
      {/*<TimelineDialog open={dialog4Open} close={handleDialog4Close}>*/}
      {/*  <Box color={colorPrimary}>*/}
      {/*    <Typography*/}
      {/*      className={'font-family-secondary'}*/}
      {/*      variant="h3"*/}
      {/*      fontSize={{*/}
      {/*        xs: '2rem',*/}
      {/*        md: '2.125rem',*/}
      {/*        lg: '2.5rem',*/}
      {/*      }}*/}
      {/*      marginBottom={'1.5rem'}*/}
      {/*    >*/}
      {/*      {wave4Title}*/}
      {/*    </Typography>*/}
      {/*    <Typography>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do*/}
      {/*      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim*/}
      {/*      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
      {/*      aliquip ex ea commodo consequat. Duis aute irure dolor in*/}
      {/*      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
      {/*      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in*/}
      {/*      culpa qui officia deserunt mollit anim id est laborum.*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</TimelineDialog>*/}
    </React.Fragment>
  );
};

export default PageTimeline;
