import React from 'react';
import PropTypes from 'prop-types';

const IframeEmbed = ({ height, style, src, width }) => (
  <div className="video-responsive" style={style}>
    <iframe
      width={width}
      height={height}
      src={src}
      scrolling="no"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;  picture-in-picture; web-share"
      allowFullScreen="true"
    />
  </div>
);

IframeEmbed.propTypes = {
  height: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default IframeEmbed;
